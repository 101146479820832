import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import hubHome from '@/components/hubHome.vue';
import envlist from '@/components/MhEnvironments/envlist.vue';
import envdetails from '@/components/MhEnvironments/envdetails.vue';
import requestlist from '@/components/MhRequests/requestlist.vue';
import requestdetails from '@/components/MhRequests/requestdetails.vue';
import cognitoauth from '@/components/cognitoAuth.vue';
import customerlist from '@/components/MhCustomers/customerList.vue';
import customerdetails from '@/components/MhCustomers/customerdetails.vue';
import costdashboard from '@/components/HostingCosts/mhcostdashboard.vue';
import productversions from '@/components/ProductVersions/productversions.vue';
import productversionsdetails from '@/components/ProductVersions/productversiondetails.vue';
import clouddashboard from '@/components/Cloud/clouddashboard.vue';
import CloudReporting from '@/components/CloudReporting/CloudReporting.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/signin',
        component: cognitoauth
    },
    {
        path: '/env/:id',
        component: envdetails
    },
    {
        path: '/envs',
        name: 'envs',
        component: envlist
    },
    {
        path: '/customers',
        component: customerlist
    },
    {
        path: '/customer/:id',
        component: customerdetails
    },
    {
        path: '/requests',
        component: requestlist
    },
    {
        path: '/request/:id',
        component: requestdetails
    },
    {
        path: '/costdashboard',
        component: costdashboard
    },
    {
        path: '/productversions',
        component: productversions
    },
    {
        path: '/productversions/:version',
        component: productversionsdetails
    },
    {
        path: '/clouddashboard',
        component: clouddashboard
    },
    {
        path: '/cloudreporting',
        component: CloudReporting
    },
    {
        path: '/',
        component: hubHome
    }
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    next();
});

export default router;
