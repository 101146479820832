
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'CloudReporting',
        data() {
            return {
                activeTab: 'Dashboard',
                // loads <tabName>Tab.vue from current directory as a component
                tabNames: [
                    'Dashboard',
                    'Realms',
                    'Accounts'
                ]
            };
        },
        computed: {
            tabs() {
                return this.tabNames.map((c) => () => import(
                        `@/components/CloudReporting/${c.replace(' ','')}Tab.vue`
                    ));
            }
        },
    });
